export interface ICartItem {
  COLLECTION_ID: number;
  COLLECTION_SUBTYPE: string;
  product: { sku: { SKU_BASE_ID: string } };
  coll_info?: {
    COLLECTION_TYPE?: string;
  };
  'sku.SKU_BASE_ID': string;
  ITEM_QUANTITY: number;
}

export interface IEventResultData {
  ac_results: {
    result: {
      PREVIOUS_ITEM_QUANTITY: number;
      CARTITEM: {
        ITEM_QUANTITY: number;
        'sku.SKU_BASE_ID': string;
        CART_ID: number;
      };
      COLLECTION_ID?: number;
    };
  }[];
  coll_info?: {
    COLLECTION_ID?: number;
    COLLECTION_TYPE?: string;
  };
  trans_data?: {
    order: {
      items: {
        COLLECTION_ID: number;
        'sku.SKU_BASE_ID': string;
      }[];
      kits: {
        COLLECTION_ID: number;
        'sku.SKU_BASE_ID': string;
      }[];
    };
  };
  getItem(): ICartItem;
  getAllItems(): ICartItem[];
}

export interface IComponentOverrides {
  override: {
    value?: string;
    styles?: string;
    attribute?: string;
    className?: string;
    text?: boolean;
    mode?: 'modal' | 'overlay';
  };
  elementName: CartDrawerSlotName | CartDrawerComponentName;
}

export interface IOpenCartEvent {
  quantity: number;
  offerCode?: string;
  skuId?: string;
  collectionId?: number;
}

export interface IMountCartDrawer {
  cartDrawerEndpoint?: string;
}

export interface ICartDrawerInjection {
  aud_seg_allow: boolean;
  cart_drawer: boolean;
  cart_drawer_automount: boolean;
  has_audseg: boolean;
  drawer_route: string;
}

/**
 * These are the available slots in the cart drawer.
 * The rest of the cart drawer components are part of the Shadow DOM
 * and currently there is not support for overriding them.
 */
export type CartDrawerSlotName =
  | 'cart-header'
  | 'cart-header-close'
  | 'messages'
  | 'thumbnail'
  | 'name'
  | 'shade-color'
  | 'shade-name'
  | 'size'
  | 'remove'
  | 'cart-count'
  | 'cart-estimated-total-label'
  | 'cart-estimated-total-value'
  | 'cart-additional-message'
  | 'button-go-shopping'
  | 'button-view-cart'
  | 'button-checkout';

/**
 * These are the available web components in the cart drawer.
 * Each component has a shadow root with the available slots.
 */
export type CartDrawerComponentName =
  | 'cart-drawer'
  | 'cart-header'
  | 'cart-item'
  | 'cart-items-list'
  | 'cart-footer';

interface objectType {
  errorObjects: unknown[];
}
export type IErrorResponseData = unknown[] | objectType;

export const atbEvents = ['addToCart.success', 'dwAddToCart.success'];
export const atbFailureEvents = ['addToCart.failure'];

const suppressedPages = [
  '/checkout/viewcart.tmpl',
  '/cart',
  '/guest-checkout',
  '/checkout',
  '/sign-in',
  '/sign-up'
];

const suppressedPlatforms = ['tomfordbeauty'];

const matchLocation = (suppressed: string[], location: string) =>
  !!suppressed.some((path) => location.includes(path));

const getCookie = (cookieWithValue: string) => {
  return document.cookie
    .split(';')
    .some((item) => item.trim().toUpperCase().startsWith(cookieWithValue));
};

const debugMode = () => getCookie('VULCANIZE_CART_DEBUG=1');

const removeGenericOverlay = () => {
  const generic = typeof window.generic === 'object' ? window.generic : {};
  generic?.overlay?.hide();
};

const removeLegacyErrorOverlay = () => {
  const observer = new MutationObserver(() => {
    removeGenericOverlay();
  });

  const maxErrorMessageElement = document.querySelector('[data-test-id="max_error_message"]');
  const cboxContentElement = document.querySelector('#cboxContent');
  const element = maxErrorMessageElement || cboxContentElement || document.body;

  observer.observe(element, { childList: true, subtree: true });

  return observer;
};

export const controllerEvents = [
  'openCart@window->cart-drawer#open',
  'failCart@window->cart-drawer#fail',
  'fetchCartDrawer@window->cart-drawer#updateContent',
  'closeCart@window->cart-drawer#close'
];

export const isVulcan = () => window._platform === 'vulcan';

export const forceMount = () => getCookie('VULCANIZE_CART=1');

export const debugConsole = (message: string) => {
  if (debugMode()) {
    // eslint-disable-next-line no-console
    console.info(message);
  }
};

export const skipCartDrawer = () => {
  let matchesTenant = false;
  const pathname = window.location.pathname;
  const hostname = window.location.hostname;
  const params = window.location.search;

  if (params) {
    const tenant = new URLSearchParams(params).get('tenant') || '';
    matchesTenant = matchLocation(suppressedPlatforms, tenant);
  }

  const shouldSkipMount =
    matchLocation(suppressedPages, pathname) || matchLocation(suppressedPlatforms, hostname);

  return shouldSkipMount || matchesTenant;
};

export const failingCartEvent = (errorMessage: string) => {
  const observer = removeLegacyErrorOverlay();

  const detail = {
    blockUpdate: true,
    cartMode: 'modal',
    error: true,
    errorMessage
  };
  const newCartEvent = new CustomEvent('openCart', { detail });
  window.dispatchEvent(newCartEvent);

  setTimeout(() => {
    observer.disconnect();
  }, 500);
};

export const openCartEvent = (params: IOpenCartEvent) => {
  const observer = removeLegacyErrorOverlay();

  const { quantity, collectionId, offerCode, skuId } = params;

  const detail = {
    cartMode: 'modal',
    quantity
  };

  if (skuId) {
    detail['skuId'] = skuId;
  }

  if (offerCode) {
    detail['offerCode'] = offerCode;
  }

  if (collectionId) {
    detail['collectionId'] = collectionId;
  }

  const newCartEvent = new CustomEvent('openCart', { detail });
  window.dispatchEvent(newCartEvent);

  setTimeout(() => {
    observer.disconnect();
  }, 500);
};
