import {
  atbEvents,
  atbFailureEvents,
  debugConsole,
  failingCartEvent,
  ICartItem,
  IEventResultData,
  IErrorResponseData,
  openCartEvent
} from './helpers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const $: any;

const removeOfferPopup = () => {
  const popups = document.querySelectorAll('.sop--v2__success-popup');
  popups.forEach((popup: HTMLElement) => {
    popup.remove();
  });
};

const fetchCartDrawer = () => {
  const newCartEvent = new CustomEvent('fetchCartDrawer');
  window.dispatchEvent(newCartEvent);
};

const getRecentAddedItem = (resultData: IEventResultData) => {
  const acResultsData = resultData.ac_results;
  const transData = resultData?.trans_data;
  if (acResultsData.length === 1) {
    return acResultsData[0].result;
  } else {
    const recentItemCollectionId = acResultsData?.[0].result.COLLECTION_ID;
    const recentItem = transData?.order?.items?.filter(
      (eachItem) => eachItem.COLLECTION_ID === recentItemCollectionId
    );
    const recentItemSkuId = recentItem?.[0]['sku.SKU_BASE_ID'];
    const filteredAcResult = acResultsData.filter((eachItem) => {
      return (
        eachItem.result?.CARTITEM?.CART_ID !== null &&
        eachItem.result?.CARTITEM?.['sku.SKU_BASE_ID'] === recentItemSkuId
      );
    });
    return filteredAcResult?.[0].result;
  }
};

const handleDWAddToCart = (collectionId: number, allItems: ICartItem[] = []) => {
  if (allItems.length > 0) {
    const itemInCart = allItems.find((item) => item.COLLECTION_ID === collectionId);

    if (
      itemInCart &&
      itemInCart.COLLECTION_SUBTYPE === 'EL_DW_DRAMMING' &&
      itemInCart['sku.SKU_BASE_ID']
    ) {
      return itemInCart['sku.SKU_BASE_ID'];
    }
  }
};

const removeLegacyOfferPopup = () => {
  const observer = new MutationObserver(() => {
    removeOfferPopup();
  });

  observer.observe(document.body, { childList: true, subtree: true });
};

const mapATBResults = (resultData: IEventResultData) => {
  const rawResults = resultData.ac_results;
  let collectionId: number | undefined;
  let quantity = 1;
  let skuId = '';

  const getQuantityValue = (
    qtySelectorElement: Element | null,
    skuBaseId: string,
    itemQuantity: number
  ) => {
    if (qtySelectorElement && qtySelectorElement.textContent) {
      const qty = qtySelectorElement.textContent;
      return parseInt(qty, 10);
    } else {
      const recentAddedItem = document.querySelector(
        `[data-cart-item-sku-id-value="${skuBaseId}"]`
      );
      const recentAddedItemPrevQty =
        recentAddedItem?.getAttribute('data-cart-item-qty-value') || '0';
      const previousItemQuantity = parseInt(recentAddedItemPrevQty, 10);
      const quantity =
        itemQuantity > previousItemQuantity ? itemQuantity - previousItemQuantity : itemQuantity;
      return quantity;
    }
  };

  if (rawResults) {
    const { PREVIOUS_ITEM_QUANTITY = 0, CARTITEM } = getRecentAddedItem(resultData);
    const { ITEM_QUANTITY = 1, 'sku.SKU_BASE_ID': skuBaseId = '' } = CARTITEM;
    // the data response sent in the event triggered by Drupal does not contain the added quantity
    quantity = ITEM_QUANTITY - PREVIOUS_ITEM_QUANTITY;
    skuId = skuBaseId;

    if (
      resultData.coll_info &&
      resultData.coll_info.COLLECTION_TYPE &&
      resultData.trans_data?.order
    ) {
      const COLLECTION_ID = resultData.coll_info.COLLECTION_ID;
      const skuData = resultData.trans_data.order.kits.find(
        (kit) => kit.COLLECTION_ID === COLLECTION_ID
      );
      if (skuData) {
        collectionId = COLLECTION_ID;
        skuId = skuData['sku.SKU_BASE_ID'];
      }
    }
  } else {
    const item = resultData.getItem();
    const allItems = resultData.getAllItems() || [];
    const { COLLECTION_ID, product, ITEM_QUANTITY: itemQuantity = 1 } = item;
    const { sku } = product;
    const { SKU_BASE_ID: skuBaseId = '' } = sku;

    const hasEngraving = item?.coll_info?.COLLECTION_TYPE === 'MONO';

    const qtySelectorElement = document.querySelector(
      `[data-sku-base-id="${skuBaseId}"] [data-test-id="cart_product_quantity"]`
    );

    skuId = handleDWAddToCart(COLLECTION_ID, allItems) || skuBaseId;

    collectionId = COLLECTION_ID;
    const quantityValue = getQuantityValue(qtySelectorElement, skuBaseId, itemQuantity);
    quantity = hasEngraving ? 1 : quantityValue;
  }

  return { collectionId, quantity, skuId };
};

const atbOffer = (offerElement: Element) => {
  try {
    const offerCode = offerElement.getAttribute('data-offercode') || '';
    const eventName = offerCode ? `offer:${offerCode}:applied` : '';

    try {
      removeLegacyOfferPopup();
    } catch {
      // eslint-disable-next-line no-console
      debugConsole('[Cart 3.0] Unable to remove legacy offer popup');
    }

    if (eventName) {
      $(document).on(eventName, () => {
        openCartEvent({ offerCode, quantity: 1 });
      });
    }
  } catch {
    fetchCartDrawer();
  }
};

const allowedErrorMessageKeys = [
  'allocation.qty_reduced',
  'cart.item_limit',
  'cart.qty_limit.reduced'
];

const getErrorMessage = (resultData: IErrorResponseData) => {
  const _resultData = resultData ?? [];
  const messages = Array.isArray(_resultData)
    ? _resultData
    : _resultData.errorObjects
    ? _resultData.errorObjects
    : [];

  if (messages.length > 0) {
    const filteredMessages = messages.filter((message: { key: string }) => {
      if (message && typeof message === 'object' && 'key' in message) {
        return allowedErrorMessageKeys.includes(message.key);
      }
      return false;
    });

    const firstMessage = filteredMessages[0] as { text: string };

    if (typeof firstMessage === 'object' && firstMessage?.text) {
      return firstMessage.text;
    }
  }

  return '';
};

export const isDrp = () => 'jQuery' in window && typeof window.jQuery === 'function';

export const mountOnVulcanATB = (offerElement: Element | null) => {
  atbFailureEvents.forEach((atbFailureEvent) => {
    $(window).on(atbFailureEvent, function (_event: Event, resultData: unknown[]) {
      const messages = getErrorMessage(resultData);

      failingCartEvent(messages);
    });
  });

  atbEvents.forEach((atbEvent) => {
    $(window).on(atbEvent, function (_event: Event, resultData: IEventResultData) {
      try {
        const isMultiSku = 'ac_results' in resultData && resultData.ac_results.length > 1;
        const isCollection = 'coll_info' in resultData && resultData.coll_info;
        if (isMultiSku && !isCollection) {
          const newCartEvent = new CustomEvent('openCart', {
            detail: {
              atbResponseData: { result: { data: resultData } },
              cartMode: 'modal',
              quantity: this.quantityValue
            }
          });
          window.dispatchEvent(newCartEvent);

          return;
        }

        const { collectionId, quantity, skuId } = mapATBResults(resultData);
        openCartEvent({ skuId, quantity, collectionId });
      } catch (error) {
        debugConsole(`[Cart 3.0] Unable to trigger Drupal ATB cart modal - ${error}`);
      }
    });
  });

  if (offerElement) {
    atbOffer(offerElement);
  }
};
